<template>
  <div :style="{
    backgroundImage:
      'url(' + require('@/assets/images/ytjyDetailsBg.png') + ')',
    backgroundColor: headerBgColor,
  }" class="boxSty">
    <van-nav-bar title="业务档案" @click-left="goback()"> </van-nav-bar>

    <div class="deadInfo">
      <div class="head">
        <div class="header-bg">逝者档案</div>
      </div>
      <div class="info1">
        <div>{{ model.deceasedBasicInfo.deadName }}</div>
      </div>
      <div class="info2">
        <!-- 0：未结算   1||3：已结算  2：取消结算 -->
        <div class="line-item">
          <div class="label">结算状态</div>
          <div class="val-style">
            <div v-if="model.deceasedBasicInfo.settlementStatus == 0">未结算</div>
            <div v-else-if="model.deceasedBasicInfo.settlementStatus == 2">取消结算</div>
            <div v-else>已结算</div>
          </div>
        </div>
        <div class="line-item">
          <div class="label">总消费</div>
          <div class="val-style">
            ￥{{ model.deceasedBasicInfo.totalAmount }}
          </div>
        </div>
      </div>
    </div>
    <div class="deadInfo">
      <div class="title">
        <div>逝者档案</div>
      </div>
      <div class="info2">
        <div class="line-item">
          <div class="label">姓名</div>
          <div class="val-style">{{ model.deceasedBasicInfo.deadName ?? '-' }}</div>
        </div>
        <div class="line-item">
          <div class="label">身份证号</div>
          <div class="val-style">
            {{ model.deceasedBasicInfo.deadCardCode ?? '-' }}
          </div>
        </div>
        <div class="line-item">
          <div class="label">年龄</div>
          <div class="val-style">{{ model.deceasedBasicInfo.deadAge ?? '-' }}</div>
        </div>
        <!-- 0:未知的性别 1:男性 2:女性 9:未说明的性别 -->
        <div class="line-item">
          <div class="label">性别</div>
          <div class="val-style">
            <div v-if="model.deceasedBasicInfo.deadGender == '0'">未知的性别</div>
            <div v-else-if="model.deceasedBasicInfo.deadGender == '1'">男性</div>
            <div v-else-if="model.deceasedBasicInfo.deadGender == '2'">女性</div>
            <div v-else-if="model.deceasedBasicInfo.deadGender == '9'">未说明的性别</div>
            <div v-else>-</div>
          </div>
        </div>

        <div class="line-item">
          <div class="label">出生日期</div>
          <div class="val-style">
            {{ model.deceasedBasicInfo.deadBirthDate ?? '-' }}
          </div>
        </div>
        <div class="line-item">
          <div class="label">死亡日期</div>
          <div class="val-style">
            {{ model.deceasedBasicInfo.deadDeathDate ?? '-' }}
          </div>
        </div>

        <div class="line-item">
          <div class="label">录入时间</div>
          <div class="val-style">{{ model.deceasedBasicInfo.deadCreateTime ?? '-' }}</div>
        </div>

        <div class="line-item">
          <div class="label">户籍</div>
          <div class="val-style">
            {{ model.deceasedBasicInfo.deadHomeCode ?? '-' }}
          </div>
        </div>
        <!-- 1:正常遗体  2:非正常遗体 3:特殊遗体 -->
        <div class="line-item">
          <div class="label">死亡类别</div>
          <div class="val-style">
            <div v-if="model.deceasedBasicInfo.deadRemainsType == '1'">正常遗体</div>
            <div v-else-if="model.deceasedBasicInfo.deadRemainsType == '2'">非正常遗体</div>
            <div v-else-if="model.deceasedBasicInfo.deadRemainsType == '3'">特殊遗体</div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="line-item">
          <div class="label">到馆时间</div>
          <div class="val-style">
            {{ model.deceasedBasicInfo.deadArriveTime ?? '-' }}
          </div>
        </div>
      </div>
    </div>
    <div class="deadInfo">
      <div class="title">
        家属信息
      </div>
      <div class="info2">
        <div class="line-item">
          <div class="label">姓名</div>
          <div class="val-style">{{ model.deceasedBasicInfo.contactName ?? '-' }}</div>
        </div>
        <div class="line-item">
          <div class="label">身份证号</div>
          <div class="val-style">{{ model.deceasedBasicInfo.contactCardCode ?? '-' }}</div>
        </div>
        <div class="line-item">
          <div class="label">联系电话</div>
          <div class="val-style">{{ model.deceasedBasicInfo.contactPhone ?? '-' }}</div>
        </div>
        <div class="line-item">
          <div class="label">与逝者关系</div>
          <div class="val-style">{{relationship[model.deceasedBasicInfo.contactDeadRelation]}}</div>
        </div>
      </div>
    </div>
    <div class="deadInfo evidence-info">
      <div class="title">
        证明材料
      </div>
      <div class="imgBox">
        <div class="imgItem" v-if="model.deceasedBasicInfo.deathCertFile"
          @click="imgViews(model.deceasedBasicInfo.deathCertFile)">
          <img :src="basurl + model.deceasedBasicInfo.deathCertFile" alt="" />

          <div class="imgText">死亡证明</div>
        </div>
        <div class="imgItem" v-if="model.deceasedBasicInfo.permanentResidenceBooklet"
          @click="imgViews(model.deceasedBasicInfo.permanentResidenceBooklet)">
          <img :src="
            basurl + model.deceasedBasicInfo.permanentResidenceBooklet
          " alt="" />
          <div class="imgText">户口本</div>
        </div>
        <div class="imgItem" v-if="model.deceasedBasicInfo.powerBookUrl"
          @click="imgViews(model.deceasedBasicInfo.powerBookUrl)">
          <img :src="basurl + model.deceasedBasicInfo.powerBookUrl" alt="" />
          <div class="imgText">委托书</div>
        </div>
      </div>
    </div>

    <!-- 业务流程 -->
    <div class="business-process">
      <div class="title">业务流程</div>
      <div class="process">
        <div class="process-item" v-for="(item, index) in serviceStatusItems" :key="index">
          <div class="icon-container">
            <div class="icon">
              <img v-if="item.finishStatus == '0'" src="../../assets/images/remainPickUp/icon-not.png" />
              <img v-if="item.finishStatus == '1'" src="../../assets/images/remainPickUp/icon-wait.png" />
              <img v-if="item.finishStatus == '2'" src="../../assets/images/remainPickUp/finish.png" />
            </div>
            <div class="dshaed-line"></div>
          </div>
          <div class="name-container">
            <div class="name">{{ item.serviceName }}</div>
            <div class="status" v-if="!item.staffName">未开始</div>
            <div class="status">操作员：{{ item.staffName }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="businessInfo">
      <van-tabs v-model="active" color="#47B992">
        <van-tab :name="key" v-for="(value, key, index) in serviceCostMap" :key="index">
          <template #title>
            <span v-if="key == 1">接运</span>
            <span v-else-if="key == 2">防腐整容</span>
            <span v-else-if="key == 3">遗体外运</span>
            <span v-else-if="key == 4">冷藏</span>
            <span v-else-if="key == 5">告别</span>
            <span v-else-if="key == 6">火化</span>
            <span v-else-if="key == 7">寄存</span>
            <span v-else-if="key == 8">殡葬用品</span>
          </template>
          <div class="deadInfo">
            <div class="title">
              业务信息
            </div>
            <div class="info2" v-for="(item, index) in value" :key="index">
              <div class="line-item">
                <div class="label">序号</div>
                <div class="val-style">{{ index + 1 }}</div>
              </div>
              <div class="line-item">
                <div class="label">收费名称</div>
                <div class="val-style">{{ item.chargeName }}</div>
              </div>
              <!--01:次   02：个 -->
              <div class="line-item">
                <div class="label">价格单位</div>
                <div class="val-style">
                  <div v-if="item.priceUnit == '01'">次</div>
                  <div v-if="item.priceUnit == '02'">个</div>
                </div>
              </div>
              <div class="line-item">
                <div class="label">销售单价</div>
                <div class="val-style">￥{{ item.salesPrice }}</div>
              </div>
              <div class="line-item">
                <div class="label">销售数量</div>
                <div class="val-style">{{ item.salesNum }}</div>
              </div>
              <div class="line-item">
                <div class="label">销售总价</div>
                <div class="val-style">￥{{ item.totalPrice }}</div>
              </div>
              <!--  收费类型  -->
              <!-- 01：政府定价  02：政府指导价  03：政府调节价  99：其他  -->
              <div class="line-item">
                <div class="label">收费类型</div>
                <div class="val-style">
                  <div v-if="item.type == '01'">政府定价</div>
                  <div v-if="item.type == '02'">政府指导价</div>
                  <div v-if="item.type == '03'">政府调节价</div>
                  <div v-if="item.type == '99'">其他</div>
                </div>
              </div>
              <!--服务收费类型  -->
              <!-- 01：基本服务  02： 选择性服务(延伸服务)-->
              <div class="line-item">
                <div class="label">服务收费类型</div>
                <div class="val-style">
                  <div v-if="item.item == '01'">基本服务</div>
                  <div v-if="item.item == '02'">选择性服务(延伸服务)</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- <van-tab title="遗体告别" name="2"></van-tab>
        <van-tab title="遗体火化" name="3"></van-tab>
        <van-tab title="遗体外运" name="4">
          <businessInfo />
        </van-tab>
        <van-tab title="遗体火化" name="5"></van-tab>
        <van-tab title="遗体火化" name="6"></van-tab>
        <van-tab title="遗体火化" name="7"></van-tab> -->
      </van-tabs>
    </div>
  </div>
</template>

<script>
import headerBg from "@/assets/images/ytjyDetailsBg.png";
import iconBusinessFile from "@/assets/images/icon-businessFile.png";
import icon01 from "@/assets/images/remainPickUp/icon-01.png";
import icon02 from "@/assets/images/remainPickUp/icon-02.png";
import icon03 from "@/assets/images/remainPickUp/icon-03.png";
import icon04 from "@/assets/images/remainPickUp/icon-04.png";
import iconAlready from "@/assets/images/remainPickUp/icon-already.png";
import iconNot from "@/assets/images/remainPickUp/icon-not.png";
import iconWait from "@/assets/images/remainPickUp/icon-wait.png";

import testImg from "@/assets/images/screenshot-4.png";
import testImg2 from "@/assets/images/screenshot-4.png";
import { showFailToast, showImagePreview } from "vant";
import { ETansportFile, } from "@/api/remainsPickUp";
import {getDicts} from '@/api/common.js'
export default {
  // 业务档案
  name: "businessFile",

  data() {
    return {
      basurl: process.env.VUE_APP_FILE_PATH,
      testImg,
      testImg2,
      headerBg,
      iconBusinessFile,
      icon01,
      icon02,
      icon03,
      icon04,
      iconAlready,
      iconNot,
      iconWait,
      headerBgColor: "#f3f4f6",
      model: {
        allServiceCostDetail: {},
        corpseVehicleDispatch: {},
        corpseVehicleSend: {},
        deceasedBasicInfo: {},
        serviceCostDetail: {},
        serviceHandlerStatus: [],
        serviceInfo: {},
      },
      serviceStatusItems: [], //业务流程
      tabs: [], //标签栏数量list
      serviceCostMap: {}, //业务信息
      active: "1",
      deadId: "", //业务标识 调接口需要
      relationship: {},
    };
  },
  created() {
    this.deadId = this.$route.query.deadId;
    this.getRelationDict();
    this.getfileInfo();
  },
  methods: {
     // 获取与逝者关系数据字典
     getRelationDict(){
      getDicts('relation').then(res=>{
        res.map((item)=>{
          this.relationship[item.value] = item.text;
        })
      })
    },
    goback() {
      // 返回上一级
      this.$router.go(-1);
    },
    getfileInfo() {
      ETansportFile(this.deadId)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            // res.data.serviceStatusItems   业务流程 array
            // res.data.serviceCostMap  业务信息 object
            // res.data 基本信息
            this.model = res.data;
            this.serviceStatusItems.push(...res.data.serviceHandlerStatus);
            this.serviceCostMap = res.data.allServiceCostDetail;
            // console.log(this.serviceCostMap);

            // console.log("this.model>>", this.model);
            // console.log("res>>>",res);
          } else {
            showFailToast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预览图片
    imgViews(imgUrl) {
      const img = this.basurl + imgUrl;
      showImagePreview({
        images: [
          img
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
.businessSty {
  .head {
    width: 100%;
    font-size: 0.25rem;
    padding: 0.08rem 0.42667rem;
  }

  .content {
    margin-bottom: 0.5rem;
  }
}

::v-deep .van-hairline--bottom::after {
  border: none;
}

::v-deep .van-nav-bar__title {
  color: #fff;
}

::v-deep .van-cell {
  padding: 0.08rem 0.42667rem;
}

::v-deep .van-cell::after {
  border: none;
}

::v-deep .van-nav-bar {
  background: none;
}

.boxSty {
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 0.6rem;
  overflow-x: hidden;
}

.deadInfo {
  width: 95%;
  padding: 0 12px;
  background-color: #fff;
  margin: 0.1rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  font-size: 18px;

  .head {
    text-align: center;
    height: 1rem;

    .header-bg {
      width: 96px;
      height: 26px;
      background: url("../../assets/images/remainPickUp/heade-tag.png") no-repeat;
      background-size: contain;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 26px;
      margin: 0 auto;
    }
  }

  .info1 {
    border-bottom: 1px solid #eeeeee;
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-bottom: 6px;
  }

  .title {
    line-height: 50px;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}

.info2 {
  padding: 10px 0;

  .line-item {
    display: flex;

    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    height: 26px;

    .label {
      color: #898989;
      min-width: 100px;
    }

    .val-style {
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.businessInfo {
  width: 95%;
  background-color: #fff;
  margin: 5px auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  padding: 1% 0 2% 0;

  .head {
    width: 100%;
    font-size: 0.25rem;
    padding: 0.08rem 0.42667rem;
  }
}

.evidence-info {

  padding-bottom: 14px;
}

.imgBox {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .imgItem {
    width: 100px;
    height: 70px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .imgText {
      font-size: 0.3rem;
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      text-align: center;
      height: 0.5rem;
      bottom: 0;
      width: 100%;
      line-height: 0.5rem;
    }
  }
}

.business-process {
  width: 95%;
  padding: 0 12px;
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  font-size: 18px;

  padding-bottom: 14px;

  .title {
    height: 52px;
    line-height: 52px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  .process {
    .process-item {
      display: flex;
      font-size: 14px;

      .icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
          width: 40px;
          height: 40px;
          background: url('../../assets/images/remainPickUp/process-icon.png');
          background-size: contain;
          position: relative;

          img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 16px;
            height: 16px;
          }
        }

        .dshaed-line {
          height: 30px;
          width: 1;
          border-left: 1px dashed #ccc;
        }
      }

      .name-container {
        margin-left: 10px;

        .name {
          color: black;
        }

        .status {
          color: rgba(137, 137, 137, 1);
          margin-top: 4px;
        }
      }
    }
  }
}
</style>